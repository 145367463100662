<template>
  <div class="template-4">
    <TemplateHeader />
    <TemplateHeroHeader />
    <TemplateOpenContent v-if="candidate.openContent" />
    <TemplateFeatureText2 v-if="candidate.featureText || candidate.featureTitle" />
    <TemplateMediaBlock />
    <div class="template-endorsements">
      <TemplateEndorsements v-if="showEndorsements" />
      <TemplateEndorsementList />
    </div>
    <TemplateEvents4 />
    <TemplateContactInfo3 />
  </div>
</template>

<script>
import TemplateHeader from "@/components/public/Header.vue";
import TemplateHeroHeader from "@/components/public/HeroHeader.vue";
import TemplateOpenContent from "@/components/public/OpenContent.vue";
import TemplateFeatureText2 from "@/components/public/FeatureText2.vue";
import TemplateMediaBlock from "@/components/public/MediaBlock.vue";
import TemplateEndorsements from "@/components/public/Endorsements.vue";
import TemplateEvents4 from "@/components/public/Events4.vue";
import TemplateContactInfo3 from "@/components/public/ContactInfo3.vue";
import TemplateEndorsementList from "@/components/public/EndorsementList.vue";

import { ThemeColorLoader } from "@/services/helpers";

export default {
  data() {
    return {
      title: "Template 4",
    };
  },
  components: {
    TemplateHeader,
    TemplateHeroHeader,
    TemplateOpenContent,
    TemplateFeatureText2,
    TemplateMediaBlock,
    TemplateEndorsements,
    TemplateEvents4,
    TemplateContactInfo3,
    TemplateEndorsementList
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
    showEndorsements() {
      return this.$store.getters["candidates/showEndorsementMenuItem"];
    },
  },
  mounted() {
    ThemeColorLoader(this.candidate.profileColor);

    let heroImg = document.querySelector(".hero-img img");
    let openContent = document.querySelector(".template-open-content");
    let featuredImg = document.querySelector(".featured-img");
    let featuredText = document.querySelector(".template-feature-text-2 .row");

    if (!heroImg) {
      openContent.classList.add("no-headshot");
    }

    if (!featuredImg) {
      openContent.classList.add("no-headshot");
      featuredText.classList.add("no-headshot");
    }

    // Removing Null link from event cards ---------------------//

    let eventLinks = document.querySelectorAll(".template-events-4 .card a");

    for (let i = 0; i < eventLinks.length; i++) {
      let linkText = eventLinks[i].textContent;

      if (linkText === "null") {
        eventLinks[i].classList.add("d-none");
      }
    }
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/public/theme/_base.scss";
@import "@/assets/styles/public/theme/template-4.scss";
</style>
